import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { withStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/Warning';
import counties from 'helpers/counties';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  label: {
    color: theme.palette.text.label,
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontSize: '0.8rem',
  },
  labelHidden: {
    display: 'none',
  },
  input: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.background.border}`,
    height: '40px',
    outline: 0,
    width: '100%',
    padding: '10px',
    '&:focus': {
      border: `2px solid ${theme.palette.background.border}`,
    },
  },
  halfWidth: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  border: {
    borderRight: `1px solid ${theme.palette.background.border}`,
    [theme.breakpoints.down('md')]: {
      borderRight: '0px',
    },
  },
  errorIcon: {
    paddingRight: '10px',
    fontSize: '2rem',
    color: theme.palette.text.error,
  },
  checkbox: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    zIndex: 2,
    height: '25px',
    width: '25px',
    '&:checked': {
      '& ~ .checkmark': {
        '&:after': {
          display: 'block',
        },
      },
    },
  },
  checkmark: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '25px',
    width: '25px',
    border: `1px solid ${theme.palette.background.border}`,
    backgroundColor: theme.palette.background.default,
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
      left: '9px',
      top: '4px',
      width: '7px',
      height: '12px',
      border: `solid ${theme.palette.background.border}`,
      borderWidth: '0 3px 3px 0',
      transform: 'rotate(45deg)',
    },
  },
  divider: {
    height: '2px',
    width: '100%',
    background: theme.palette.primary.main,
  },
  btn: {
    cursor: 'pointer',
    display: 'inline-block',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '15px 42px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
  submitting: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'white',
    opacity: 0.9,
  },
});

const ContactMethods = ['Email', 'Phone', 'Post'];

const priceRanges = [
  '£300,000 - £450,000',
  '£450,000 - £600,000',
  '£600,000 - £750,000',
  '£750,000+',
];

const numOfBedrooms = ['2', '3', '4', '5+'];

function ContactForm(props) {
  const { data, fields, classes } = props;

  const [numSelectedDevelopments, setNumSelectedDevelopments] = useState(0);

  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
  });

  // Server state handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    complete: false,
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      complete: true,
    });
  };

  const updateCheckbox = e => {
    if (e.target.checked === true) {
      setNumSelectedDevelopments(numSelectedDevelopments + 1);
    }
    if (e.target.checked === false) {
      setNumSelectedDevelopments(numSelectedDevelopments - 1);
    }
  };

  const onSubmit = async data => {
    setServerState({ submitting: true });
    const url =
      'https://hayfield-homes-server-ln726s3ggq-nw.a.run.app/send-contact-form'; // Replace with your server endpoint URL

    let developmentsInterestedIn = {};
    let index = 0;

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      if (
        checkbox.checked === true &&
        checkbox.getAttribute('data-additional')
      ) {
        // Checkbox is checked, retrieve the additional data
        const additionalData = JSON.parse(
          checkbox.getAttribute('data-additional')
        );
        const emailToAddress = additionalData.developmentEmail;
        const developmentName = additionalData.developmentName;
        // Add the email address to the array
        developmentsInterestedIn[index] = {
          emailAddress: emailToAddress,
          developmentName: developmentName,
        };
        index++;
      }
    });

    // JSON encode the array of email addresses
    const body = {
      sendToemails: developmentsInterestedIn,
      name: data.fullName,
      address_line_1: data.address_line_1,
      address_line_2: data.address_line_2,
      county: data.county,
      postcode: data.postcode,
      email: data.email,
      phone_number: data.phoneNumber,
      number_of_bedrooms: data.bedrooms !== 0 ? data.bedrooms : null,
      price_range: data.price_range !== 0 ? data.price_range : null,
      contact_by_email: data.Email === true ? true : false,
      contact_by_phone: data.Phone === true ? true : false,
      contact_by_post: data.Post === true ? true : false,
    };

    try {
      const response = await axios.post(url, JSON.stringify(body), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        handleServerResponse(true, 'Excellent, that is everything we need');

        // Assuming gtag is already loaded on the page
        if (window.gtag) {
          window.gtag('set', 'user_data', {
            first_name: data.fullName,
            email: data.email,
            phone_number: data.phoneNumber,
          });
        }
      } else {
        throw new Error('Failed to send form');
      }
    } catch (error) {
      // Handle or throw the error
      handleServerResponse(false, error);
      throw error;
    }
  };

  return (
    <Container maxWidth="md">
      <Typography component="h4" variant="h4" align="center">
        {data.form_title[0].text}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        mt={[5, 10]}
        position="relative"
      >
        {serverState.submitting && (
          <Box
            className={classes.submitting}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress />
            <Box py={1} />
            <Typography component="p" variant="h6">
              Submitting
            </Typography>
          </Box>
        )}
        {serverState.status && serverState.status.ok && (
          <Box my={4} display="flex" alignItems="center" flexDirection="column">
            <Typography component="p" variant="h6" align="center" gutterBottom>
              {serverState.status.msg}
            </Typography>
            <Typography
              component="p"
              variant="subtitle1"
              align="center"
              id="ga-register"
              data-gtm-vis-recent-on-screen-94907300_25="18938"
              data-gtm-vis-first-on-screen-94907300_25="18938"
              data-gtm-vis-total-visible-time-94907300_25="100"
              data-gtm-vis-has-fired-94907300_25="1"
            >
              One of our team will be in contact as soon as possible
            </Typography>
          </Box>
        )}
        {!serverState.complete && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Box pr={[0, 4, 4]} className={classes.border}>
                  <Box display="flex" flexDirection="column" mb={4}>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="FullName"
                      className={classes.label}
                    >
                      Your Full Name *
                    </Typography>
                    <input
                      type="text"
                      id="FullName"
                      name="fullName"
                      ref={register({ required: true })}
                      className={classes.input}
                    />
                    {errors.fullName && (
                      <Box pt={1} display="flex" alignItems="center">
                        <WarningIcon className={classes.errorIcon} />
                        <Typography
                          component="p"
                          variant="button"
                          color="error"
                        >
                          Name is required
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column" mb={4}>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="address_line_1"
                      className={classes.label}
                    >
                      Your Address
                    </Typography>
                    <Box mb={2}>
                      <input
                        type="text"
                        id="address"
                        name="address_line_1"
                        ref={register({ required: true })}
                        className={classes.input}
                      />
                    </Box>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="address_line_2"
                      className={classes.labelHidden}
                    >
                      Your Address
                    </Typography>
                    <input
                      type="text"
                      id="address"
                      name="address_line_2"
                      className={classes.input}
                    />
                    {errors.address_line_1 && (
                      <Box pt={1} display="flex" alignItems="center">
                        <WarningIcon className={classes.errorIcon} />
                        <Typography
                          component="p"
                          variant="button"
                          color="error"
                        >
                          Address is required
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column" mb={4}>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="county"
                      className={classes.label}
                    >
                      County
                    </Typography>
                    <select
                      id="county"
                      name="county"
                      ref={register({ required: true })}
                      className={classes.input}
                    >
                      {counties.map((county, item) => (
                        <option key={item} value={county}>
                          {county}
                        </option>
                      ))}
                    </select>
                    {errors.county && (
                      <Box pt={1} display="flex" alignItems="center">
                        <WarningIcon className={classes.errorIcon} />
                        <Typography
                          component="p"
                          variant="button"
                          color="error"
                        >
                          County is required
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column" mb={4}>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="postcode"
                      className={classes.label}
                    >
                      Postal Code
                    </Typography>
                    <input
                      type="text"
                      id="postcode"
                      name="postcode"
                      ref={register({ required: true })}
                      className={`${classes.input} ${classes.halfWidth}`}
                    />
                    {errors.postcode && (
                      <Box pt={1} display="flex" alignItems="center">
                        <WarningIcon className={classes.errorIcon} />
                        <Typography
                          component="p"
                          variant="button"
                          color="error"
                        >
                          Postcode is required
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column" mb={4}>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="email"
                      className={classes.label}
                    >
                      Email *
                    </Typography>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      ref={register({
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'invalid email address',
                        },
                      })}
                      className={classes.input}
                    />
                    {errors.email && (
                      <Box pt={1} display="flex" alignItems="center">
                        <WarningIcon className={classes.errorIcon} />
                        <Typography
                          component="p"
                          variant="button"
                          color="error"
                        >
                          {errors.email.message
                            ? errors.email.message
                            : 'Email address required'}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column" mb={4}>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="phoneNumber"
                      className={classes.label}
                    >
                      Phone Number *
                    </Typography>
                    <input
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      ref={register({
                        required: true,
                      })}
                      className={classes.input}
                    />
                    {errors.phoneNumber && (
                      <Box pt={1} display="flex" alignItems="center">
                        <WarningIcon className={classes.errorIcon} />
                        <Typography
                          component="p"
                          variant="button"
                          color="error"
                        >
                          Phone number required
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box pl={[0, 0, 0, 4]}>
                  <Box>
                    <Box>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.label}
                      >
                        PREFERRED METHOD OF CONTACT
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      py={2}
                    >
                      {ContactMethods.map((method, key) => (
                        <Box
                          key={key}
                          position="relative"
                          display="flex"
                          alignItems="center"
                        >
                          <Box mr={1}>
                            <input
                              type="checkbox"
                              id={method}
                              name={method}
                              ref={register}
                              className={`${classes.checkbox} checkbox`}
                            />
                            <Box className={`${classes.checkmark} checkmark`} />
                          </Box>
                          <Typography
                            component="label"
                            variant="caption"
                            htmlFor={method}
                            className={classes.label}
                          >
                            {method}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box pt={4}>
                    <Box mb={2}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.label}
                      >
                        WHICH DEVELOPMENT(S) ARE YOU INTERESTED IN?
                      </Typography>
                    </Box>
                    <Box>
                      {fields.map((item, key) => {
                        const emailTo = item?.development?.email[0]?.text
                          ? item?.development?.email[0]?.text
                          : 'marketing@hayfieldhome.co.uk';

                        const additionalData = {
                          developmentName: item.development_name[0].text,
                          developmentEmail: emailTo,
                        };

                        // Convert the additional data to a JSON string for the data attribute
                        const dataAttributes = JSON.stringify(additionalData);

                        return (
                          <Box
                            key={key}
                            position="relative"
                            display="flex"
                            alignItems="center"
                            mb={2}
                          >
                            <Box mr={1}>
                              <input
                                type="checkbox"
                                id={item.development_name[0].text}
                                name={item.development_name[0].text}
                                ref={register}
                                className={`${classes.checkbox} checkbox`}
                                onChange={e => updateCheckbox(e)}
                                data-additional={dataAttributes}
                              />
                              <Box
                                className={`${classes.checkmark} checkmark`}
                              />
                            </Box>
                            <Typography
                              component="label"
                              variant="caption"
                              htmlFor={item.development_name[0].text}
                              className={classes.label}
                            >
                              {item.development_name[0].text}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="column" my={4}>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="price_range"
                      className={classes.label}
                    >
                      Your price range
                    </Typography>
                    <select
                      id="price_range"
                      name="price_range"
                      ref={register({ required: false })}
                      className={classes.input}
                    >
                      <option value="0">Please Select:</option>
                      {priceRanges.map((price, item) => (
                        <option key={item} value={price}>
                          {price}
                        </option>
                      ))}
                    </select>
                  </Box>

                  <Box display="flex" flexDirection="column" my={4}>
                    <Typography
                      component="label"
                      variant="caption"
                      htmlFor="bedrooms"
                      className={classes.label}
                    >
                      Number of bedrooms
                    </Typography>
                    <select
                      id="bedrooms"
                      name="bedrooms"
                      ref={register({ required: false })}
                      className={classes.input}
                    >
                      <option value="0">Please Select:</option>
                      {numOfBedrooms.map((rooms, item) => (
                        <option key={item} value={rooms}>
                          {rooms}
                        </option>
                      ))}
                    </select>
                  </Box>

                  <Box my={5} className={classes.divider} />
                  <Box>
                    <input
                      type="submit"
                      className={classes.btn}
                      value="REGISTER YOUR INTEREST"
                      disabled={
                        serverState.submitting || numSelectedDevelopments === 0
                      }
                    />
                  </Box>
                  {numSelectedDevelopments === 0 && (
                    <Box>
                      <Typography
                        component="p"
                        variant="button"
                        align="left"
                        style={{ color: 'red' }}
                      >
                        Please select a development you are interested in.
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    {serverState.status && (
                      <Box my={4}>
                        {!serverState.status.ok && (
                          <Box>
                            <Typography
                              component="p"
                              variant="button"
                              align="left"
                              style={{ color: 'red' }}
                            >
                              Sorry, there has been an error with your
                              submission. Please try again or contact us at{' '}
                              {process.env.public_email} with error message{' '}
                              {serverState.status.msg}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </Container>
  );
}

ContactForm.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
